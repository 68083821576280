import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Hr, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Caffetteria CozyPlace
			</title>
			<meta name={"description"} content={"Caffetteria CozyPlace si distingue nel mare delle caffetterie per il suo impegno per la qualità, la comunità e la creatività."} />
			<meta property={"og:title"} content={"Caffetteria CozyPlace"} />
			<meta property={"og:description"} content={"Caffetteria CozyPlace si distingue nel mare delle caffetterie per il suo impegno per la qualità, la comunità e la creatività."} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1447933601403-0c6688de566e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"shortcut icon"} href={"https://images.unsplash.com/photo-1447933601403-0c6688de566e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://images.unsplash.com/photo-1447933601403-0c6688de566e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://images.unsplash.com/photo-1447933601403-0c6688de566e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://images.unsplash.com/photo-1447933601403-0c6688de566e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-startup-image"} href={"https://images.unsplash.com/photo-1447933601403-0c6688de566e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<meta name={"msapplication-TileImage"} content={"https://images.unsplash.com/photo-1447933601403-0c6688de566e?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Header>
		<Section padding="100px 0 100px 0" lg-padding="80px 0 80px 0" quarkly-title="Content-14">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					lg-width="100%"
					display="flex"
					flex-direction="row"
					justify-content="center"
					lg-margin="0px 0px 50px 0px"
				>
					<Image
						src="https://images.unsplash.com/photo-1509042239860-f550ce710b93?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
						display="block"
						object-fit="cover"
						height="100%"
						lg-width="100%"
						lg-height="700px"
						sm-height="300px"
						margin="0px 5px 0px 0px"
						md-height="500px"
						srcSet="https://images.unsplash.com/photo-1509042239860-f550ce710b93?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1509042239860-f550ce710b93?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1509042239860-f550ce710b93?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1509042239860-f550ce710b93?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1509042239860-f550ce710b93?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1509042239860-f550ce710b93?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1509042239860-f550ce710b93?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Image
						src="https://images.unsplash.com/photo-1559305616-3f99cd43e353?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
						display="block"
						object-fit="cover"
						height="100%"
						lg-width="100%"
						lg-height="700px"
						sm-height="300px"
						margin="0px 0px 0px 5px"
						md-height="500px"
						width="100%"
						srcSet="https://images.unsplash.com/photo-1559305616-3f99cd43e353?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1559305616-3f99cd43e353?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1559305616-3f99cd43e353?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1559305616-3f99cd43e353?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1559305616-3f99cd43e353?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1559305616-3f99cd43e353?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1559305616-3f99cd43e353?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					width="50%"
					padding="0px 0px 0px 80px"
					justify-content="center"
					lg-padding="0px 0px 0px 0"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 20px 0px"
						font="--headline2"
						sm-font="normal 700 42px/1.2 --fontFamily-sans"
						color="--darkL1"
						lg-margin="0px 0px 35px 0px"
						text-align="center"
					>
						Benvenuti a Caffetteria CozyPlace Coffee
					</Text>
					<Text margin="0px 0px 20px 0px" font="--lead" text-align="center">
						Il tuo nuovo punto di riferimento per una deliziosa pausa caffè
					</Text>
					<Hr
						min-height="10px"
						margin="0px 0px 20px 0px"
						width="50px"
						display="flex"
						justify-content="center"
						border-width="2px 0 0 0"
						border-color="--color-darkL1"
					/>
					<Text margin="0px 0px 25px 0px" font="--base" color="--darkL1" text-align="center">
						Caffetteria CozyPlace si distingue nel mare delle caffetterie per il suo impegno per la qualità, la comunità e la creatività. I nostri fagioli accuratamente selezionati provengono dalle migliori aziende agricole del mondo, garantendo che ogni tazza sia piena di sapore. I nostri baristi sono artigiani e realizzano ogni bevanda con cura e un tocco di creatività, assicurandosi che il tuo caffè non sia solo una bevanda ma un'esperienza.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" background="--color-darkL2" quarkly-title="Description-11">
			<Override
				slot="SectionContent"
				flex-wrap="wrap"
				max-width="1440px"
				align-items="flex-start"
				flex-direction="row"
			/>
			<Box border-color="--color-light" width="100%" border-width="0px 0px 1px 0px" border-style="solid">
				<Text margin="0px 0px 2vh 0px" color="--light" font="--lead">
					Chi siamo
				</Text>
			</Box>
			<Box width="100%" margin="3vw 0px 0px 0px">
				<Text
					margin="0px 0px 0px 0px"
					font="--headline2"
					color="--light"
					letter-spacing="1.3px"
					lg-font="normal 400 70px/1.1em &quot;Inter&quot;, sans-serif"
					sm-font="normal 400 40px/1.1em &quot;Inter&quot;, sans-serif"
				>
					Perché scegliere Caffetteria CozyPlace?
				</Text>
			</Box>
			<Box
				margin="156px 0px 100px 0px"
				lg-margin="56px 0px 30px 0px"
				md-display="none"
				width="33.333%"
				padding="20px 20px 20px 20px"
			/>
			<Box
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="3rem 0px 2rem 0px"
				lg-margin="56px 0px 30px 0px"
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
				sm-width="100%"
			>
				<Text margin="0px 0px 0px 0px" font="--headline3" color="--light">
					Realizzato con cura
				</Text>
				<Text margin="40px 0px 0px 0px" font="--base" color="--light">
					Ogni tazza di caffè è un capolavoro, preparato con attenzione ai dettagli e passione per l'eccellenza.
				</Text>
			</Box>
			<Box
				sm-width="100%"
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="3rem 0px 2rem 0px"
				lg-margin="56px 0px 30px 0px"
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
			>
				<Text margin="0px 0px 0px 0px" font="--headline3" color="--light">
					Sostenibilità al centro
				</Text>
				<Text margin="40px 0px 0px 0px" font="--base" color="--light">
					Siamo orgogliosi delle nostre pratiche ecocompatibili, dall'approvvigionamento dei fagioli alla riduzione al minimo degli sprechi.
				</Text>
			</Box>
			<Box
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
				sm-width="100%"
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="3rem 0px 2rem 0px"
				lg-margin="56px 0px 30px 0px"
			>
				<Text margin="0px 0px 0px 0px" font="--headline3" color="--light">
					Uno spazio per tutti
				</Text>
				<Text font="--base" color="--light" margin="40px 0px 0px 0px">
					Il nostro spazio accogliente e dal design creativo è perfetto per chiunque desideri godersi un'ottima tazza di caffè in un ambiente confortevole.
				</Text>
			</Box>
			<Box
				md-width="50%"
				sm-margin="20px 0px 20px 0px"
				sm-width="100%"
				width="33.333%"
				padding="20px 20px 20px 20px"
				margin="3rem 0px 2rem 0px"
				lg-margin="56px 0px 30px 0px"
			>
				<Text color="--light" margin="0px 0px 0px 0px" font="--headline3">
					Menù innovativo
				</Text>
				<Text margin="40px 0px 0px 0px" font="--base" color="--light">
					Oltre ai classici preferiti, il nostro menu presenta miscele uniche e specialità stagionali, realizzate per sorprendere e deliziare.
				</Text>
			</Box>
		</Section>
		<Section
			padding="60px 0"
			sm-padding="40px 0"
			min-height="300px"
			sm-min-height="auto"
			background="--color-light"
			display="flex"
			flex-direction="column"
		>
			<Text
				as="h1"
				margin="8px 0px 32px"
				font="--headline3"
				md-font="--headline2"
				color="--dark"
				max-width="850px"
				letter-spacing="1.3px"
			>
				Che tu stia iniziando la giornata, cercando uno spuntino a mezzogiorno o rilassandoti, siamo qui per rendere speciale ogni momento con le nostre bevande artigianali e un'atmosfera accogliente.
			</Text>
			<Box min-width="100px" min-height="auto">
				<Button margin="0px 3rem 0px 0px" type="link" href="/services" text-decoration-line="initial">
					SERVIZI
				</Button>
				<Button type="link" href="/contacts" text-decoration-line="initial">
					CONTATTI
				</Button>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				width="100%"
				lg-width="100%"
				display="flex"
				flex-direction="row"
				justify-content="center"
				lg-margin="0px 0px 50px 0px"
				margin="3rem 0px 0px 0px"
				max-height="400px"
			>
				<Image
					src="https://images.unsplash.com/photo-1445116572660-236099ec97a0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
					display="block"
					object-fit="contain"
					lg-width="100%"
					lg-height="700px"
					sm-height="300px"
					margin="0px 5px 0px 0px"
					md-height="500px"
					srcSet="https://images.unsplash.com/photo-1445116572660-236099ec97a0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1445116572660-236099ec97a0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1445116572660-236099ec97a0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1445116572660-236099ec97a0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1445116572660-236099ec97a0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1445116572660-236099ec97a0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1445116572660-236099ec97a0?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Image
					src="https://images.unsplash.com/photo-1559925393-8be0ec4767c8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
					display="block"
					object-fit="contain"
					lg-width="100%"
					lg-height="700px"
					sm-height="300px"
					margin="0px 0px 0px 5px"
					md-height="500px"
					srcSet="https://images.unsplash.com/photo-1559925393-8be0ec4767c8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1559925393-8be0ec4767c8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1559925393-8be0ec4767c8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1559925393-8be0ec4767c8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1559925393-8be0ec4767c8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1559925393-8be0ec4767c8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1559925393-8be0ec4767c8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="link4" />
			<Override slot="text" />
			<Override slot="text1" />
			<Override slot="text2" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
			<Override slot="text3" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b35f058e2e8e002176cca3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});